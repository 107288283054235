.premium-options-video {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 350px;

  @include from($tablet) {
    max-width: 600px;
  }
}

.premium-header {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.premium-lists {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @include from($tablet) {
    flex-direction: row;
  }
}

.premium-item {
  width: 350px;

  @include from($tablet) {
    width: auto;
  }
}

.custom-image {
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 3%;

  @include from($tablet) {
    width: 500px;
  }
}

.premium-card {
  box-shadow: rgba(0, 0, 0, 0.17) 0px 5px 15px;
  border-radius: 6px;
  max-width: 350px;
}

.premium-plans {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include from($tablet) {
    flex-direction: row;
  }
}

.patreon-button {
  width: 178px;
}

.manage-sub:hover {
  text-decoration: underline;
}

.space-between {
  justify-content: space-between;
}

.discount {
  padding: 8px 16px;
  border-radius: 16px;
}

.light-blue-border {
  border-color: $light-blue !important;
  border: solid;
}
