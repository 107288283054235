.demo-video {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 90%;
  max-width: 500px;

  @include from($tablet) {
    max-width: 600px;
  }

  @include from($widescreen) {
    max-width: 700px;
  }

  @include from($fullhd) {
    max-width: 800px;
  }
}

.demo-video-container {
  margin-top: -3rem;

  @include from($fullhd) {
    margin-top: -5rem;
  }
}

.decoration-container {
  height: 125px;

  @include from($fullhd) {
    height: 80px;
  }
}

.decoration-left {
  position: absolute;
  left: -300px;
  bottom: 40%;

  @include from($desktop) {
    left: -100px;
    top: 20%;
  }

  @include from($fullhd) {
    left: 0;
    top: 20%;
  }
}

.decoration-right {
  position: absolute;
  display: none;

  @include from($desktop) {
    right: -250px;
    top: -40px;
    display: block;
  }
}

.marketing-tiles {
  padding: 4rem 2rem;

  @include from($tablet) {
    padding: 4rem 4rem;
  }

  @include from($fullhd) {
    padding: 4rem 8rem;
  }
}

.handle-it-container {
  margin-left: 4rem;
}

.handle-it-text {
  margin-left: 1.5rem;
}

.vertical-decoration {
  position: absolute;
}

.cta-container {
  height: 300px;
}
