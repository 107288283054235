@import 'variables.scss';
@import 'bulma.scss';
@import 'apollo_web_fonts.css';
@import 'hero.scss';
@import 'home.scss';
@import 'footer.scss';
@import 'premium.scss';
@import 'blog.scss';

body {
  font-family: "ProximaNova-Regular";
  overflow: hidden;
}

.bold {
  font-family: "ProximaNova-Bold";
  font-weight: 700;
}

.header {
  line-height: 1.1;
}

.relative {
  position: relative;
}

.dark-blue {
  color: $dark-blue;
}

.light-blue {
  color: $light-blue;
}

.light-blue-background {
  background-color: $light-blue;
}

.dark-background {
  background-color: $space-blue;
}

.light-gray-background {
  background-color: lightgray;
}

.light-green-background {
  background-color: lightgreen;
}

.yellow {
  color: $yellow;
}

.gradient-background {
  background: rgb(3,20,47);
  background: linear-gradient(0deg, rgba(3,20,47,1) 0%, rgba(29,43,103,1) 100%);
}

.round {
  border-radius: 15px;
}

.navbar-dropdown > .navbar-item {
  color: white;

  @include from($desktop) {
    color: black;
  }
}

.navbar-burger:hover {
  color: white;
  background-color: inherit;
}

.no-pointer-events {
  pointer-events: none;
}

.patreon {
  background-color: $fiery-coral;
  color: white;
  width: 170px;

  &:hover {
    color: white;
  }
}

.light-gray-bg {
  background-color: #f0f0f0;
}

.section-content {
  padding: 3rem 0;
}

li {
  display: list-item;
  list-style-type: disc;
}
