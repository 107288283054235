a {
  color: inherit;
}

.footer-container {
  @include from($tablet) {
    margin: 0 1rem;
  }

  @include from($desktop) {
    margin: 0 4rem;
  }

  @include from($fullhd) {
    margin: 0 8rem;
  }
}

.footer {
  background-color: #f0f0f0;
}

.footer-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  @include from($tablet) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
