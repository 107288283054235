.blog p {
  margin-bottom: 24px;
}

.blog {
  max-width: 48rem;
  line-height: 28px;
  font-weight: 500;
  font-size: 18px;
}

.blog ul {
  margin-left: 16px;
  margin-bottom: 24px;
}

.blog a  {
  text-decoration: underline;
  color: #003897;
  font-family: "ProximaNova-Bold";
}

.blog button {
  max-width: 300px;
}

.blog img[src*='#center'] {
  display: block;
  margin: 36px auto;
}

.blog code {
  font-family: "ProximaNova-Bold";
  color: #003897
}

.blog h2 {
  color: #003897;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 500;
  font-family: "ProximaNova-Bold";
}

.blog-index {
  height: 60vh;
}

