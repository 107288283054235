.hero-container {
  background-image: url(/images/hero.png);
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center bottom;
}

.hero-main {
  margin: 1rem 1rem 0 1rem;
  height: 650px;

  @include from($tablet) {
    margin: 4rem 1rem 0 1rem;
    height: 550px;
  }

  @include from($desktop) {
    height: 600px;
  }

  @include from($fullhd) {
    height: 800px;
  }
}

.hero-text {
  max-width: 520px;
  margin-bottom: 1rem;
  font-size: 3rem;
  color: white;

  @include from($tablet) {
    margin-top: 5rem;
  }
}

.hero-logo {
  width: 250px;
  display: block;
  margin-right: 3rem;
}
